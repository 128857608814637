@page  :first { margin: 0 0 10px 10px; }
@page  { margin: 60px 20px; }
body {
    margin: 0 0 0px 0px;
    font-family: "helvetica";
}
body:before{
    content: ' ';
    display: block;
    position: absolute;
    left: 30%;
    top: 30%;
    width: 40%;
    height: 40%;
    opacity: 0.3;
    /* background: url('./images/icon.jpg') no-repeat; */
    background-position: 50% 50%;
    background-size: contain;
    z-index: 0;
}
p {
    margin: 0;
}
.header {
    width: 100%;
}

h4 {
    margin-bottom: 0px;
    margin-top: 15px;
}
.invoice-body {
    margin-top: 30px;
    z-index: 999;
}
table.invoice-body {
    border: 0;
    border-collapse: separate;
    border-spacing: 0 5px;
    z-index: 999;
}

table.invoice-body thead tr th {
    border-bottom: 3px solid #000;
    border-collapse: separate;
    border-spacing: 5px 5px;
    z-index: 999;
}

table.invoice-body tbody tr#first td {
    /*border-top: 3px solid #4d4d4d;*/
    border-collapse: separate;
    border-spacing: 5px 5px;
    z-index: 999;
}

table.invoice-body tfoot tr th {
    border-bottom: 3px solid #000;
    border-top: 3px solid #000;
    border-collapse: separate;
    border-spacing: 5px 5px;
    padding-top: 20px;
    padding-bottom: 10px;
    z-index: 999;
}
.info {
    margin: 20px 0;
   width: 100%;
    position: relative;
    z-index: 999;
    background-color: #FFF;
}

.company-info p{
    line-height: 23px;
    display: block;
}

.bank-detail {
    margin: 20px 0;
    display: block;
    line-height: 23px;
}
.bg-img {
    width:150px;
    height:67px;
    background-image: url('https://opinion-nation.com/wp-content/themes/optionnation/optionnation/assets/images/log.png');
    background-repeat: no-repeat;
    background-position:  center center;
    position: absolute;
    background-size: contain;
    right: 0;
    top: 0;
}
.bg-img img{
    width: 150px;
}
.footer-thankyou{
    position: fixed;
    width: 100%;
    bottom: 30px;
    left: 0;
    right: 0;
    overflow: hidden;
    height:38px;
}